/* eslint-disable jsx-a11y/anchor-is-valid */
export const Footer = () => {
  return (
    <footer className="py-3 my-4">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <a href="#" className="nav-link px-2 text-muted">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link px-2 text-muted">
            Profile
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link px-2 text-muted">
            Term and Condition
          </a>
        </li>
      </ul>
      <p className="text-center text-muted">© 4BBY Empire Inc</p>
    </footer>
  );
};
